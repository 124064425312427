#error-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #7a7a7a96;
}

#error {
  width: 400px;
  min-height: 150px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#error span {
  color: #f00;
}

#error a {
  margin-top: 20px;
}

button.close {
  padding: 5px;
  border: none;
}
