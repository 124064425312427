body,
#root,
.App {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  color: #163d65;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: rgb(106, 106, 106);
  margin: 2px 0;
  border-radius: 5px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: #eeeeee;
  background-image: url(../public/bg-responsa.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

#content-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#header {
  width: 100%;
  height: 80px;
  background-color: #ffffffe0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 100px;
}

h5 {
  max-width: 500px;
}

.descr {
  font-size: 12px;
  max-width: 500px;
}

section {
  margin-top: 100px;
  margin: 50px;
  padding: 20px;
  padding-bottom: 50px;
  background-color: #fff;
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section.question {
  max-width: 700px;
}

#result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#line {
  height: 1px;
  width: 80%;
  opacity: 0.25;
  background-color: #163d65;
}

button,
.custom-file-upload {
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: #163d65;
  border: 1px solid #163d65;
  font-size: 13px;
  background-color: transparent;
  cursor: pointer;
  height: 50px;
}

.home-button,
.custom-file-upload {
  margin: 30px 0;
  width: 180px;
}

.custom-file-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-file-upload strong {
  margin: 0;
}

.black-button {
  color: #fff;
  background-color: #163d65;
}

.small-button {
  border: none;
  margin: 30px 0;
}

button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

button:hover,
input:hover,
.custom-file-upload:hover {
  opacity: 0.5;
}

div.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

div.buttons-container > * {
  margin: 10px;
}

input[type='file'] {
  display: none;
}

#error {
  color: #f00;
}

#results {
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

#result-box {
  width: 45%;
}

#result-box span {
  font-weight: bold;
}

#result-box p.analysis-descr {
  font-weight: 200;
  padding: 15px;
  font-size: 15px;
  height: 180px;
  margin: 0;
  width: 100%;
  overflow-y: scroll;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#result-box p.analysis-value {
  font-style: italic;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 5px;
  font-size: 15px;
  max-height: 400px;
  overflow-y: scroll;
  white-space: pre-line;
}

#result-box div.analysis-value-placeholder {
  padding: 15px;
  border-radius: 5px;
  font-size: 15px;
  max-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
