.tabs-container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabs-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tabs-buttons-container button {
  width: 140px;
  font-size: 14px;
  border: none;
  background-color: #f8f8f8;
  margin: 0 5px;
}

.tabs-buttons-container button:hover {
}

.tabs-buttons-container button.selected {
  border-bottom: 4px solid #163d65;
}

.tabs-component {
  /* border: 1px solid #000; */
  background-color: #f8f8f8;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 80%;
}
