.analyze-text-container {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#analyze-text-input {
  min-height: 300px;
  width: 100%;
  padding: 10px;
}
