.custom-file-upload {
  margin-right: 20px;
}

.select-container {
  height: 50px;
  width: 150px;
}

.content-description {
  font-size: 12px;
  margin: 20px;
  margin-bottom: 0;
}
