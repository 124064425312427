.wrapper {
  position: relative;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 500px;
}

input[type='checkbox'] {
  position: absolute;
  top: -100px;
}

.main-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#buttons button {
  width: 200px;
}

.clip {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
